import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import firebase from "firebase/compat/app";
import 'firebase/compat/auth';

const DatabaseDelete = () => {
    const [step, setStep] = useState(1);
    const [confirmationCode] = useState(Math.floor(100000 + Math.random() * 900000).toString());
    const [userInputCode, setUserInputCode] = useState("");
    const [userId, setUserId] = useState("");
    const [finalConfirmation, setFinalConfirmation] = useState(false);
    const [dateValid, setDateValid] = useState(false);
    const [backupConfirmed, setBackupConfirmed] = useState(false);
    const [backupName, setBackupName] = useState("");

    useEffect(() => {
        const today = new Date();
        const start = new Date(today.getFullYear(), 4, 23);
        const end = new Date(today.getFullYear(), 7, 1);
        setDateValid(today >= start && today <= end);
    }, []);

    const nextStep = () => setStep(step + 1);

    const verifyId = () => {
        const user = JSON.parse(localStorage.getItem("user"));
        console.log(user);
        const queryFor = (user.email).replace(/\./g, ",");
        var retrievedData = {};
        firebase
            .database()
            .ref(`reg2025/${queryFor}`)
            .once("value")
            .then((snapshot) => {
                if (snapshot.exists()) {
                retrievedData = snapshot.val(); 
                console.log("Email:", retrievedData.idnumber); 
                if (String(userId) != String(retrievedData.idnumber)) {
                    alert("ID does not match the ID in the database.");
                    return;
                }

                setStep(step + 1);
                } else {
                console.log("No data found at the specified path.");
                }
            })
            .catch((error) => {
                alert("Error retrieving data: " + error);
        });

        console.log(userId);
    }

    const handleDelete = () => {
        const user = JSON.parse(localStorage.getItem("user"));
        console.log(user);
        const queryFor = (user.email).replace(/\./g, ",");
        var retrievedData = {};

        firebase
            .database()
            .ref(`reg2025/${queryFor}`)
            .once("value")
            .then((snapshot) => {
                if (snapshot.exists()) {
                retrievedData = snapshot.val(); 
                console.log("Email:", retrievedData.pisd); 
                if (String(userId) != String(retrievedData.idnumber)) {
                    alert("ID does not match the ID in the database.");
                    return;
                }
                } else {
                console.log("No data found at the specified path.");
                }
            })
            .catch((error) => {
                alert("Error retrieving data: " + error);
        });

        if (backupConfirmed) {
            // const backupRef = firebase.database().ref(`backup/${backupName}`);
            // backupRef.set(retrievedData);
            if (" " in backupName || backupName === "") {
                alert("Backup name cannot contain spaces and cannot be empty.");
                return;
            }

            var backupNamesList = [];
            firebase
                .database()
                .ref("archive")
                .once("value")
                .then((snapshot) => {
                    if (snapshot.exists()) {
                        const data = snapshot.val();
                        backupNamesList = Object.keys(data);
                        if (backupNamesList.includes(backupName)) {
                            alert("Backup name already exists. Please choose a different name.");
                            return;
                        }
                    }
                })
                .catch((error) => {
                    alert("Error retrieving data: " + error);
                });

            var reg2025_data;
            var credits_data;
            var creditsspring_data;
            var events_data;

            const dataPromises = {
                reg2025: firebase.database().ref('reg2025').once('value'),
                credits: firebase.database().ref('credits').once('value'),
                creditsspring: firebase.database().ref('creditsspring').once('value'),
                events: firebase.database().ref('events').once('value')
            };

            Promise.all(Object.values(dataPromises))
            .then((snapshots) => {
                reg2025_data = snapshots[0].val();
                credits_data = snapshots[1].val();
                creditsspring_data = snapshots[2].val();
                events_data = snapshots[3].val();
                
                console.log("All data retrieved successfully");
                // const backupRef = firebase.database().ref(`backup/${backupName}`);
                // backupRef.set({
                //   reg2025: reg2025_data,
                //   credits: credits_data,
                //   creditsspring: creditsspring_data,
                //   events: events_data
                // });
                const backupRef = firebase.database().ref(`archive/${backupName}`);
                backupRef.set({
                    reg2025: reg2025_data,
                    credits: credits_data,
                    creditsspring: creditsspring_data,
                    events: events_data
                });
            })
            .catch((error) => {
                console.error("Error retrieving data:", error);
            });
        }

        const userResponse = window.confirm("This is the FINAL warning. Are you sure you want to reset the DB? This action CANNOT be undone.");

        if (userResponse) {
            console.log("User clicked OK");

            const updates = {};
            updates['reg2025'] = null;
            updates['credits'] = null;
            updates['creditsspring'] = null;
            updates['events'] = null;
            
            // Apply all updates at once
            firebase.database().ref().update(updates)
                .then(() => {
                    alert("Data has been successfully reset.");
                    setStep(1);
                })
                .catch((error) => {
                console.error("Error resetting data:", error);
                    alert("Error resetting data: " + error.message);
                });
        } else {
            alert("Deletion cancelled.");
            return;
        }

    };

    return (
        <main className="flex-1 relative z-0 overflow-y-auto pt-2 pb-6 focus:outline-none md:py-6" tabIndex="0">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                <div className="bg-gray-100 p-6 rounded-lg mt-3">
                    <h1 className="text-2xl font-semibold text-gray-900">Reset the NHS Database</h1>
                    <h1 className="text-xl text-gray-400">WARNING: This action cannot be undone. Only do this at the end of the second semester when approved.</h1>
                    
                    <div className="mt-3">
                        <button className="bg-red-500 p-2 rounded-lg text-white" onClick={nextStep} disabled={step > 1}>Proceed to Backup Confirmation</button>
                    </div>
                    
                    {step >= 2 && (
                        <div className="mt-3">
                            <div className="flex items-center">
                                <input 
                                    type="checkbox" 
                                    id="backup" 
                                    checked={backupConfirmed}
                                    onChange={(e) => setBackupConfirmed(e.target.checked)}
                                />
                                <label htmlFor="backup" className="ml-2 text-lg">make a backup (optional)</label>
                            </div>
                            {backupConfirmed && (
                                <input
                                    type="text"
                                    placeholder="Enter backup name"
                                    className="border p-2 rounded w-full mt-2"
                                    value={backupName}
                                    onChange={(e) => setBackupName(e.target.value)}
                                />
                            )}
                            <button className="bg-red-500 p-2 rounded-lg text-white mt-3 block" onClick={nextStep}>
                                Continue
                            </button>
                        </div>
                    )}

                    {step >= 3 && (
                        <div className="mt-3">
                            <p className="text-gray-900 text-lg">Please type the following confirmation code: {confirmationCode}</p>
                            <input 
                                type="text" 
                                className="border p-2 rounded w-full mt-2" 
                                value={userInputCode} 
                                onChange={(e) => setUserInputCode(e.target.value)}
                            />
                            <button 
                                className="bg-red-500 p-2 rounded-lg text-white mt-3 block" 
                                onClick={() => userInputCode === confirmationCode && nextStep()}
                                disabled={step > 3}
                            >
                                Confirm Code
                            </button>
                        </div>
                    )}

                    {step >= 4 && (
                        <div className="mt-3">
                            <p className="text-gray-900 text-lg">Please enter your ID to confirm:</p>
                            <input 
                                type="text" 
                                className="border p-2 rounded w-full mt-2" 
                                value={userId} 
                                onChange={(e) => setUserId(e.target.value)}
                            />
                            <button 
                                className="bg-red-500 p-2 rounded-lg text-white mt-3 block" 
                                onClick={verifyId}
                                disabled={step > 4}
                            >
                                Verify ID
                            </button>
                        </div>
                    )}

                    {step >= 5 && (
                        <div className="mt-3">
                            <p className="text-gray-900 text-lg">Are you absolutely sure you want to delete the database?</p>
                            <div className="flex items-center">
                                <input 
                                    type="checkbox" 
                                    id="finalConfirm" 
                                    checked={finalConfirmation} 
                                    onChange={() => setFinalConfirmation(!finalConfirmation)}
                                />
                                <label htmlFor="finalConfirm" className="ml-2 text-lg">Yes, I am 100% sure</label>
                            </div>
                            <button 
                                className="bg-red-500 p-2 rounded-lg text-white mt-3 block disabled:bg-red-100" 
                                onClick={handleDelete}
                                disabled={!finalConfirmation  || !dateValid}
                            >
                                Confirm Deletion (Available only from May 23 - Aug 1)
                            </button>
                            {!dateValid && <p className="text-red-500 mt-2">Deletion is only allowed between May 23 and August 1.</p>}
                        </div>
                    )}
                </div>
            </div>
        </main>
    );
}

export default DatabaseDelete;
