import React from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
} from "react-router-dom";
import 'firebase/compat/auth';
import AdminHome from "./HomePage";
import AdminProjects from "./StudentTableView";
import GrantAdminPage from './grantAdminPage';
import DatabaseQueryPage from './databaseQueryPage';
import AdminEvents from "./EventsList";
import CreditFall from "./creditLogFall";
import CreditSpring from "./creditLogSpring";
import Approval from "./CreditLog";
import AdminEventsInfo from "./EventInfo";
import AdminEventsQR from "./adminEventsQR";
import DatabaseDelete from "./databaseDelete";
import DatabaseRollback from "./databaseRollback";
import firebase from "firebase/compat/app";
import 'firebase/compat/auth';

// ***Page Description***
// Sidebar, this is hub for all navigation
// Includes Dashboard, Students, Credit Log, and Events
// ***********************
export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      room: "https://meet.jit.si/helloooej23",
      semesterLock: "both",
      portalLock: "no",
    };
  }

  updateFirebase = (key, value) => {
    firebase
      .database()
      .ref(`${key}`)
      .set(value)
      .then(() => console.log(`Updated ${key}: ${value}`))
      .catch((error) => console.error("Firebase update failed:", error));
  };

  handleSemesterChange = (event) => {
    this.setState({ semesterLock: event.target.value });
    const value = event.target.value;
    this.updateFirebase("semlock", value);
  };

  handlePortalChange = (event) => {
    this.setState({ portalLock: event.target.value });
    const value = event.target.value;
    this.updateFirebase("portallock", value);
  };

  componentDidMount = () => {
    console.log("Hello");

    firebase
      .database()
      .ref("semlock")
      .once("value")
      .then((snapshot) => {
        if (snapshot.exists()) {
          this.setState({ semesterLock: snapshot.val() });
        }
      })
      .catch((error) => console.error("Error fetching semlock:", error));

    firebase
      .database()
      .ref("portallock")
      .once("value")
      .then((snapshot) => {
        if (snapshot.exists()) {
          this.setState({ portalLock: snapshot.val() });
        }
      })
      .catch((error) => console.error("Error fetching portallock:", error));
  };


  render() {
    return (
      <div class="bg-white-100 h-full flex flex-row">
        <div
          class="w-56 h-full"
          style={{
            backgroundColor: "#F4F5F7",
            borderRight: "1px solid rgb(223, 225, 230)",
          }}
        >
          <div class="flex flex-row w-56 py-6 px-1">
            <div
              class="rounded-md mx-3 w-10 h-10"
              style={{ backgroundColor: "#FF5630" }}
            >
              <svg
                width={40}
                height={40}
                viewBox="0 0 128 128"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                style={{ borderRadius: "3px" }}
              >
                <defs>
                  <rect id="path-1" x={0} y={0} width={128} height={128} />
                </defs>
                <g
                  id="Page-1"
                  stroke="none"
                  strokeWidth={1}
                  fill="none"
                  fillRule="evenodd"
                >
                  <g id="project_avatar_settings">
                    <g>
                      <mask id="mask-2" fill="white">
                        <use xlinkhref="#path-1" />
                      </mask>
                      <use
                        id="Rectangle"
                        fill="#FF5630"
                        style={{ fill: "#FF5630" }}
                        xlinhref="#path-1"
                      />
                      <g id="Settings" fillRule="nonzero">
                        <g transform="translate(20.000000, 17.000000)">
                          <path
                            d="M74.578,84.289 L72.42,84.289 C70.625,84.289 69.157,82.821 69.157,81.026 L69.157,16.537 C69.157,14.742 70.625,13.274 72.42,13.274 L74.578,13.274 C76.373,13.274 77.841,14.742 77.841,16.537 L77.841,81.026 C77.842,82.82 76.373,84.289 74.578,84.289 Z"
                            id="Shape"
                            fill="#2A5083"
                          />
                          <path
                            d="M14.252,84.289 L12.094,84.289 C10.299,84.289 8.831,82.821 8.831,81.026 L8.831,16.537 C8.831,14.742 10.299,13.274 12.094,13.274 L14.252,13.274 C16.047,13.274 17.515,14.742 17.515,16.537 L17.515,81.026 C17.515,82.82 16.047,84.289 14.252,84.289 Z"
                            id="Shape"
                            fill="#2A5083"
                          />
                          <rect
                            id="Rectangle-path"
                            fill="#153A56"
                            x="8.83"
                            y="51.311"
                            width="8.685"
                            height="7.763"
                          />
                          <path
                            d="M13.173,53.776 L13.173,53.776 C6.342,53.776 0.753,48.187 0.753,41.356 L0.753,41.356 C0.753,34.525 6.342,28.936 13.173,28.936 L13.173,28.936 C20.004,28.936 25.593,34.525 25.593,41.356 L25.593,41.356 C25.593,48.187 20.004,53.776 13.173,53.776 Z"
                            id="Shape"
                            fill="#FFFFFF"
                          />
                          <path
                            d="M18.021,43.881 L8.324,43.881 C7.453,43.881 6.741,43.169 6.741,42.298 L6.741,41.25 C6.741,40.379 7.453,39.667 8.324,39.667 L18.021,39.667 C18.892,39.667 19.604,40.379 19.604,41.25 L19.604,42.297 C19.605,43.168 18.892,43.881 18.021,43.881 Z"
                            id="Shape"
                            fill="#2A5083"
                            opacity="0.2"
                          />
                          <rect
                            id="Rectangle-path"
                            fill="#153A56"
                            x="69.157"
                            y="68.307"
                            width="8.685"
                            height="7.763"
                          />
                          <path
                            d="M73.499,70.773 L73.499,70.773 C66.668,70.773 61.079,65.184 61.079,58.353 L61.079,58.353 C61.079,51.522 66.668,45.933 73.499,45.933 L73.499,45.933 C80.33,45.933 85.919,51.522 85.919,58.353 L85.919,58.353 C85.919,65.183 80.33,70.773 73.499,70.773 Z"
                            id="Shape"
                            fill="#FFFFFF"
                          />
                          <path
                            d="M78.348,60.877 L68.651,60.877 C67.78,60.877 67.068,60.165 67.068,59.294 L67.068,58.247 C67.068,57.376 67.781,56.664 68.651,56.664 L78.348,56.664 C79.219,56.664 79.931,57.377 79.931,58.247 L79.931,59.294 C79.931,60.165 79.219,60.877 78.348,60.877 Z"
                            id="Shape"
                            fill="#2A5083"
                            opacity="0.2"
                          />
                          <path
                            d="M44.415,84.289 L42.257,84.289 C40.462,84.289 38.994,82.821 38.994,81.026 L38.994,16.537 C38.994,14.742 40.462,13.274 42.257,13.274 L44.415,13.274 C46.21,13.274 47.678,14.742 47.678,16.537 L47.678,81.026 C47.678,82.82 46.21,84.289 44.415,84.289 Z"
                            id="Shape"
                            fill="#2A5083"
                          />
                          <rect
                            id="Rectangle-path"
                            fill="#153A56"
                            x="38.974"
                            y="23.055"
                            width="8.685"
                            height="7.763"
                          />
                          <path
                            d="M43.316,25.521 L43.316,25.521 C36.485,25.521 30.896,19.932 30.896,13.101 L30.896,13.101 C30.896,6.27 36.485,0.681 43.316,0.681 L43.316,0.681 C50.147,0.681 55.736,6.27 55.736,13.101 L55.736,13.101 C55.736,19.932 50.147,25.521 43.316,25.521 Z"
                            id="Shape"
                            fill="#FFFFFF"
                          />
                          <path
                            d="M48.165,15.626 L38.468,15.626 C37.597,15.626 36.885,14.914 36.885,14.043 L36.885,12.996 C36.885,12.125 37.597,11.413 38.468,11.413 L48.165,11.413 C49.036,11.413 49.748,12.125 49.748,12.996 L49.748,14.043 C49.748,14.913 49.036,15.626 48.165,15.626 Z"
                            id="Shape"
                            fill="#2A5083"
                            opacity="0.2"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <div class="flex flex-col justify-center">
              <span
                class="text-sm leading-tight font-semibold"
                style={{ color: "#42526e" }}
              >
                Admin
              </span>
              <span
                class="text-sm leading-tight font-normal"
                style={{ color: "#42526e" }}
              >
                PESH NHS
              </span>
            </div>
          </div>
          <div class="mx-2">
            <NavLink
              exact
              to="/"
              activeClassName="bg-gray-200 activeAdmin"
              className="mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-gray-600 rounded-md hover:bg-gray-200 transition ease-in-out duration-150"
            >
              <svg
                className="mr-3 h-6 w-6 transition ease-in-out duration-150"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M3 12l9-9 9 9M5 10v10a1 1 0 001 1h3a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1h3a1 1 0 001-1V10M9 21h6"
                />
              </svg>
              Dashboard
            </NavLink>
            {/* <NavLink
              to="/admin/settings"
              activeClassName="bg-gray-200 activeAdmin"
              className="mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-gray-600 rounded-md hover:bg-gray-200 transition ease-in-out duration-150"
            >
              <svg
                className="mr-3 h-6 w-6 transition ease-in-out duration-150"
                stroke="none"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  stroke-width="1"
                  d="M5 4a1 1 0 00-2 0v7.268a2 2 0 000 3.464V16a1 1 0 102 0v-1.268a2 2 0 000-3.464V4zM11 4a1 1 0 10-2 0v1.268a2 2 0 000 3.464V16a1 1 0 102 0V8.732a2 2 0 000-3.464V4zM16 3a1 1 0 011 1v7.268a2 2 0 010 3.464V16a1 1 0 11-2 0v-1.268a2 2 0 010-3.464V4a1 1 0 011-1z"
                />
              </svg>
              Settings
            </NavLink> */}
            <NavLink
              to="/students"
              activeClassName="bg-gray-200 activeAdmin"
              className="mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-gray-600 rounded-md hover:bg-gray-200 transition ease-in-out duration-150"
            >
              <svg
                class="mr-3 h-6 w-6 transition ease-in-out duration-150"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                ></path>
              </svg>
              Students
            </NavLink>
            <NavLink
              to="/approve"
              activeClassName="bg-gray-200 activeAdmin"
              className="mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-gray-600 rounded-md hover:bg-gray-200 transition ease-in-out duration-150"
            >
              <svg
                class="mr-3 h-6 w-6 transition ease-in-out duration-150"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
                ></path>
              </svg>
              Credit Log
            </NavLink>
            <NavLink
              to="/events"
              activeClassName="bg-gray-200 activeAdmin"
              className="mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-gray-600 rounded-md hover:bg-gray-200 transition ease-in-out duration-150"
            >
              <svg
                class="mr-3 h-6 w-6 transition ease-in-out duration-150"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
                ></path>
              </svg>
              Events
            </NavLink>
            <NavLink
              to="/grantAdmin"
              activeClassName="bg-gray-200 activeAdmin"
              className="mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-gray-600 rounded-md hover:bg-gray-200 transition ease-in-out duration-150"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" width="24" height="24" stroke-width="2" class='mr-3'>
                <path d="M6 21l15 -15l-3 -3l-15 15l3 3"></path>
                <path d="M15 6l3 3"></path>
                <path d="M9 3a2 2 0 0 0 2 2a2 2 0 0 0 -2 2a2 2 0 0 0 -2 -2a2 2 0 0 0 2 -2"></path>
                <path d="M19 13a2 2 0 0 0 2 2a2 2 0 0 0 -2 2a2 2 0 0 0 -2 -2a2 2 0 0 0 2 -2"></path>
              </svg>
              Grant Admin
            </NavLink>
            <NavLink
              to="/databaseQuery"
              activeClassName="bg-gray-200 activeAdmin"
              className="mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-gray-600 rounded-md hover:bg-gray-200 transition ease-in-out duration-150"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" width="24" height="24" stroke-width="2" class='mr-3'>
                <path d="M4 6c0 1.657 3.582 3 8 3s8 -1.343 8 -3s-3.582 -3 -8 -3s-8 1.343 -8 3"></path>
                <path d="M4 6v6c0 1.657 3.582 3 8 3c.361 0 .716 -.009 1.065 -.026"></path>
                <path d="M20 13v-7"></path>
                <path d="M4 12v6c0 1.657 3.582 3 8 3"></path>
                <path d="M16 22l5 -5"></path>
                <path d="M21 21.5v-4.5h-4.5"></path>
              </svg>
              Query Database
            </NavLink>
            <div class="adminDivider"></div>
            <h1 class="text-sm font-semibold text-black mb-3">DANGER ZONE</h1>
            <NavLink
              to="/databaseDelete"
              activeClassName="bg-gray-200 activeAdmin"
              className="mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-white bg-red-500 rounded-md hover:bg-red-400 transition ease-in-out duration-150"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" width="24" height="24" stroke-width="2" class="mr-3">
                <path d="M4 7l16 0"></path>
                <path d="M10 11l0 6"></path>
                <path d="M14 11l0 6"></path>
                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
              </svg>
              Reset DB
            </NavLink>
            <NavLink
              to="/databaseRollback"
              activeClassName="bg-gray-200 activeAdmin"
              className="mt-1 group flex items-center px-2 py-2 text-sm leading-5 font-medium text-white bg-red-500 rounded-md hover:bg-red-400 transition ease-in-out duration-150"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" width="24" height="24" stroke-width="2" class="mr-3">
                <path d="M4 7l16 0"></path>
                <path d="M10 11l0 6"></path>
                <path d="M14 11l0 6"></path>
                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
              </svg>
              Rollback DB
            </NavLink>

            <label className="block mt-3">
              <span className="text-gray-700">Semester Lock</span>
              <select
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                value={this.state.semesterLock}
                onChange={this.handleSemesterChange}
              >
                <option value="fall">Fall</option>
                <option value="spring">Spring</option>
                <option value="both">Both</option>
                <option value="none">None</option>
              </select>
            </label>

            <label className="block mt-2">
              <span className="text-gray-700">Portal Lock</span>
              <select
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                value={this.state.portalLock}
                onChange={this.handlePortalChange}
              >
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </label>
          </div>
        </div>
        <Switch>
          <Route path="/" exact>
            <AdminHome />
          </Route>
          <Route path="/students" exact>
            <AdminProjects />
          </Route>
          <Route path="/creditspring/:uid">
            <CreditSpring />
          </Route>
          <Route path="/creditfall/:uid">
            <CreditFall />
          </Route>
          <Route path="/approve">
            <Approval email={this.props.email} />
          </Route>
          <Route path="/events" exact>
            <AdminEvents />
          </Route>
          <Route path="/grantAdmin" exact>
            <GrantAdminPage />
          </Route>
          <Route path="/databaseQuery" exact>
            <DatabaseQueryPage />
          </Route>
          <Route path="/databaseDelete" exact>
            <DatabaseDelete />
          </Route>
          <Route path="/databaseRollback" exact>
            <DatabaseRollback />
          </Route>
          <Route path="/events/:id" exact>
            <AdminEventsInfo />
          </Route>
          <Route path="/events/:id/showCode" exact>
            <AdminEventsQR />
          </Route>
        </Switch>
      </div>
    );
  }
}
