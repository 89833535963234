import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import firebase from "firebase/compat/app";
import 'firebase/compat/auth';
import "react-dropzone-uploader/dist/styles.css";

const DatabaseQueryPage = () => {
    const [currentShirts, setCurrentShirts] = useState([]);
    const [currentShirtSizes, setCurrentShirtSizes] = useState({});
    const [currentInductedLists, setCurrentInductedLists] = useState([]);
    const [page, setPage] = useState("");
    const [currentSemesterHoursCheckbox, setCurrentSemesterHoursCheckbox] = useState({
        sem: 'both',
        type: 'notmet'
    });
    const [currentStudentData, setCurrentStudentData] = useState({});
    const [currentSemesterHours, setCurrentSemesterHours] = useState({});

    const handleSemesterChange = (event) => {
        setCurrentSemesterHoursCheckbox(prevState => ({
            ...prevState,
            sem: event.target.value
        }));
    };

    // fname, lname, id, grade, fall hours, spring hours, met?
    // fname, lname, id, grade, fall hours, met?
    // fname, lnam, id, grade, spring hours, met?

    const handleTypeChange = (event) => {
        setCurrentSemesterHoursCheckbox(prevState => ({
            ...prevState,
            type: event.target.value
        }));
    };

    const getShirts = () => {
        firebase.auth().currentUser.getIdToken(true)
            .then((idToken) => {
                return fetch("https://api.peshnhs.org/databaseShirtSizes", {
                    method: "GET",
                    headers: { 'idtoken': idToken }
                });
            })
            .then((result) => result.json())
            .then((result) => {
                setCurrentShirts(result['table']);
                setCurrentShirtSizes(result['sizes']);
                setPage("Query Shirt Sizes");
            })
            .catch((error) => console.log(error));
    }

    const getSemesterHours = async () => {
        console.log("here")
        try {
            const idToken = await firebase.auth().currentUser.getIdToken(true);
    
            const [dataResponse, hoursResponse] = await Promise.all([
                fetch("https://api.peshnhs.org/getData", {
                    method: "GET",
                    headers: { 'idtoken': idToken }
                }).then(res => res.json()),
                
                fetch("https://api.peshnhs.org/databaseHours", {
                    method: "GET",
                    headers: { 'idtoken': idToken }
                }).then(res => res.json())
            ]);
    
            setCurrentStudentData({
                students: dataResponse['students'],
                studentsKeys: dataResponse['array']
            });
    
            setCurrentSemesterHours({
                ...hoursResponse['result']
            });
    
            setPage("Query Semester Hours");
        } catch (error) {
            console.error(error);
        }
    };    

    const getInductedLists = () => {
        firebase.auth().currentUser.getIdToken(true)
            .then((idToken) => {
                return fetch("https://api.peshnhs.org/databaseInducted", {
                    method: "GET",
                    headers: { 'idtoken': idToken }
                });
            })
            .then((response) => response.json())
            .then((data) => {
                setCurrentInductedLists(data['inductedlist']);
                setPage("Query Induction Lists");
            })
            .catch((error) => console.log(error));
    };

    const renderExtras = () => {
        if (page === "Query Shirt Sizes") {
            return (
                <>
                    <h1 className="text-lg">Small: {currentShirtSizes["S"]}</h1>
                    <h1 className="text-lg">Medium: {currentShirtSizes["M"]}</h1>
                    <h1 className="text-lg">Large: {currentShirtSizes["L"]}</h1>
                    <h1 className="text-lg">Extra Large: {currentShirtSizes["XL"]}</h1>
                    <h1 className="text-lg">Double Extra Large: {currentShirtSizes["XXL"]}</h1>
                    <h1 className="text-lg font-semibold mt-5 mb-5 text-center cursor-pointer" onClick={() => downloadCSV2(currentShirts, 'shirt_sizes.csv')}>Download CSV</h1>
                </>
            )
        }
        else if (page == "Query Semester Hours") {
            return (
                <>
                    <div className="mb-4">
                        <label htmlFor="semester" className="block text-sm font-medium">Select Semester</label>
                        <select
                            id="semester"
                            value={currentSemesterHoursCheckbox.sem}
                            onChange={handleSemesterChange}
                            className="mt-2 block w-full max-w-xl p-2 border border-gray-300 rounded"
                        >
                            <option value="both">Both</option>
                            <option value="fall">Fall</option>
                            <option value="spring">Spring</option>
                        </select>
                    </div>

                    <div className="mb-4">
                        <label htmlFor="type" className="block text-sm font-medium">Select Type</label>
                        <select
                            id="type"
                            value={currentSemesterHoursCheckbox.type}
                            onChange={handleTypeChange}
                            className="mt-2 block w-full max-w-xl p-2 border border-gray-300 rounded"
                        >
                            <option value="notmet">Not Met</option>
                            <option value="met">Met</option>
                            <option value="both">Both</option>
                        </select>
                    </div>
                    <h1 className="text-lg font-semibold mt-5 ml-1 mb-5 text-left cursor-pointer" onClick={() => downloadCSV3(renderRows, 'customized_sem_hours.csv')}>Download Customized CSV</h1>
                </>
            )
        }
    }

    var headers2 = []
    var renderRows = []
    const renderCustomHoursTables = () => {
        if (page == "Query Semester Hours") {

            var type1 = "";
            var type2 = "";
            
            if (currentSemesterHoursCheckbox.sem === "fall") {
                headers2 = ['First Name', 'Last Name', 'PISD', 'ID', 'Grade', 'Fall Hours', 'Met?'];
                type1 = "fallonly";
            }
            else if (currentSemesterHoursCheckbox.sem === "spring") {
                headers2 = ['First Name', 'Last Name', 'PISD', 'ID', 'Grade', 'Spring Hours', 'Met?'];
                type1 = "springonly";
            }
            else if (currentSemesterHoursCheckbox.sem === "both") {
                headers2 = ['First Name', 'Last Name', 'PISD', 'ID', 'Grade', 'Fall Hours', 'Spring Hours', 'Met?'];
                type1 = "both";
            }

            if (currentSemesterHoursCheckbox.type == "notmet" ) {
                type2 = 'notmet';
            }
            else if (currentSemesterHoursCheckbox.type == "met") {
                type2 = 'met';
            }
            else if (currentSemesterHoursCheckbox.type == "both") {
                type2 = 'both';
            }

            // check for type1, and then filter thru the currentSemesterHours array by having each dict only have the headers and check if met or not met based on type 2.

            if (type1 === "fallonly") {
                if (type2 === "notmet") {
                    for (let i = 0; i < Object.keys(currentSemesterHours).length; i++) {
                        if (currentStudentData.students[Object.keys(currentSemesterHours)[i]]) {
                            if (currentSemesterHours[Object.keys(currentSemesterHours)[i]].fallhours < 15) {
                                renderRows.push({ fname: currentStudentData.students[Object.keys(currentSemesterHours)[i]].lfirstname, lname: currentStudentData.students[Object.keys(currentSemesterHours)[i]].lastname, email: currentStudentData.students[Object.keys(currentSemesterHours)[i]].pisd, id: currentStudentData.students[Object.keys(currentSemesterHours)[i]].idnumber, grade: currentStudentData.students[Object.keys(currentSemesterHours)[i]].gradelevel, fhours: currentSemesterHours[Object.keys(currentSemesterHours)[i]].fallhours, met: "No" });
                            }
                        }
                    }
                } else if (type2 === "met") {
                    for (let i = 0; i < Object.keys(currentSemesterHours).length; i++) {
                        if (currentStudentData.students[Object.keys(currentSemesterHours)[i]]) {
                        console.log(Object.keys(currentSemesterHours)[i])
                        console.log(currentStudentData.students[Object.keys(currentSemesterHours)[i]])
                        if (currentSemesterHours[Object.keys(currentSemesterHours)[i]].fallhours >= 15) {
                            renderRows.push({ fname: currentStudentData.students[Object.keys(currentSemesterHours)[i]].lfirstname, lname: currentStudentData.students[Object.keys(currentSemesterHours)[i]].lastname, email: currentStudentData.students[Object.keys(currentSemesterHours)[i]].pisd, id: currentStudentData.students[Object.keys(currentSemesterHours)[i]].idnumber, grade: currentStudentData.students[Object.keys(currentSemesterHours)[i]].gradelevel, fhours: currentSemesterHours[Object.keys(currentSemesterHours)[i]].fallhours, met: "Yes" });
                        }
                    }
                    }
                } else if (type2 === "both") {
                    for (let i = 0; i < Object.keys(currentSemesterHours).length; i++) {
                        if (currentStudentData.students[Object.keys(currentSemesterHours)[i]]) {
                        if (currentSemesterHours[Object.keys(currentSemesterHours)[i]].fallhours >= 15) {
                            renderRows.push({ fname: currentStudentData.students[Object.keys(currentSemesterHours)[i]].lfirstname, lname: currentStudentData.students[Object.keys(currentSemesterHours)[i]].lastname, email: currentStudentData.students[Object.keys(currentSemesterHours)[i]].pisd, id: currentStudentData.students[Object.keys(currentSemesterHours)[i]].idnumber, grade: currentStudentData.students[Object.keys(currentSemesterHours)[i]].gradelevel, fhours: currentSemesterHours[Object.keys(currentSemesterHours)[i]].fallhours, met: "Yes" });
                        }
                        else if (currentSemesterHours[Object.keys(currentSemesterHours)[i]].fallhours < 15) {
                            renderRows.push({ fname: currentStudentData.students[Object.keys(currentSemesterHours)[i]].lfirstname, lname: currentStudentData.students[Object.keys(currentSemesterHours)[i]].lastname, email: currentStudentData.students[Object.keys(currentSemesterHours)[i]].pisd, id: currentStudentData.students[Object.keys(currentSemesterHours)[i]].idnumber, grade: currentStudentData.students[Object.keys(currentSemesterHours)[i]].gradelevel, fhours: currentSemesterHours[Object.keys(currentSemesterHours)[i]].fallhours, met: "No" });
                        }
                    }
                    }
                }
            }

            else if (type1 === "springonly") {
                if (type2 === "notmet") {
                    for (let i = 0; i < Object.keys(currentSemesterHours).length; i++) {
                        if (currentStudentData.students[Object.keys(currentSemesterHours)[i]]) {
                        if (currentSemesterHours[Object.keys(currentSemesterHours)[i]].springhours < 15) {
                            renderRows.push({ fname: currentStudentData.students[Object.keys(currentSemesterHours)[i]].lfirstname, lname: currentStudentData.students[Object.keys(currentSemesterHours)[i]].lastname, email: currentStudentData.students[Object.keys(currentSemesterHours)[i]].pisd, id: currentStudentData.students[Object.keys(currentSemesterHours)[i]].idnumber, grade: currentStudentData.students[Object.keys(currentSemesterHours)[i]].gradelevel, shours: currentSemesterHours[Object.keys(currentSemesterHours)[i]].springhours, met: "No" });
                        }
                    }
                    }
                } else if (type2 === "met") {
                    for (let i = 0; i < Object.keys(currentSemesterHours).length; i++) {
                        if (currentStudentData.students[Object.keys(currentSemesterHours)[i]]) {
                        if (currentSemesterHours[Object.keys(currentSemesterHours)[i]].springhours >= 15) {
                            renderRows.push({ fname: currentStudentData.students[Object.keys(currentSemesterHours)[i]].lfirstname, lname: currentStudentData.students[Object.keys(currentSemesterHours)[i]].lastname, email: currentStudentData.students[Object.keys(currentSemesterHours)[i]].pisd, id: currentStudentData.students[Object.keys(currentSemesterHours)[i]].idnumber, grade: currentStudentData.students[Object.keys(currentSemesterHours)[i]].gradelevel, shours: currentSemesterHours[Object.keys(currentSemesterHours)[i]].springhours, met: "Yes" });
                        }
                    }
                    }
                } else if (type2 === "both") {
                    for (let i = 0; i < Object.keys(currentSemesterHours).length; i++) {
                        if (currentStudentData.students[Object.keys(currentSemesterHours)[i]]) {
                        if (currentSemesterHours[Object.keys(currentSemesterHours)[i]].springhours >= 15) {
                            renderRows.push({ fname: currentStudentData.students[Object.keys(currentSemesterHours)[i]].lfirstname, lname: currentStudentData.students[Object.keys(currentSemesterHours)[i]].lastname, email: currentStudentData.students[Object.keys(currentSemesterHours)[i]].pisd, id: currentStudentData.students[Object.keys(currentSemesterHours)[i]].idnumber, grade: currentStudentData.students[Object.keys(currentSemesterHours)[i]].gradelevel, shours: currentSemesterHours[Object.keys(currentSemesterHours)[i]].springhours, met: "Yes" });
                        }
                        else if (currentSemesterHours[Object.keys(currentSemesterHours)[i]].springhours < 15) {
                            renderRows.push({ fname: currentStudentData.students[Object.keys(currentSemesterHours)[i]].lfirstname, lname: currentStudentData.students[Object.keys(currentSemesterHours)[i]].lastname, email: currentStudentData.students[Object.keys(currentSemesterHours)[i]].pisd, id: currentStudentData.students[Object.keys(currentSemesterHours)[i]].idnumber, grade: currentStudentData.students[Object.keys(currentSemesterHours)[i]].gradelevel, shours: currentSemesterHours[Object.keys(currentSemesterHours)[i]].springhours, met: "No" });
                        }
                    }
                    }
                }
            }

            else if (type1 === "both") {
                console.log("hereeeeeeee")
                if (type2 === "notmet") {
                    for (let i = 0; i < Object.keys(currentSemesterHours).length; i++) {
                        if (currentStudentData.students[Object.keys(currentSemesterHours)[i]]) {
                        if (currentSemesterHours[Object.keys(currentSemesterHours)[i]].springhours < 15 || currentSemesterHours[Object.keys(currentSemesterHours)[i]].fallhours < 15) {
                            renderRows.push({ fname: currentStudentData.students[Object.keys(currentSemesterHours)[i]].lfirstname, lname: currentStudentData.students[Object.keys(currentSemesterHours)[i]].lastname, email: currentStudentData.students[Object.keys(currentSemesterHours)[i]].pisd, id: currentStudentData.students[Object.keys(currentSemesterHours)[i]].idnumber, grade: currentStudentData.students[Object.keys(currentSemesterHours)[i]].gradelevel, shours: currentSemesterHours[Object.keys(currentSemesterHours)[i]].springhours, fhours: currentSemesterHours[Object.keys(currentSemesterHours)[i]].fallhours, met: "No" });
                        }
                    }
                    }
                } else if (type2 === "met") {
                    for (let i = 0; i < Object.keys(currentSemesterHours).length; i++) {
                        if (currentStudentData.students[Object.keys(currentSemesterHours)[i]]) {
                        if (currentSemesterHours[Object.keys(currentSemesterHours)[i]].springhours >= 15 && currentSemesterHours[Object.keys(currentSemesterHours)[i]].fallhours >= 15) {
                            renderRows.push({ fname: currentStudentData.students[Object.keys(currentSemesterHours)[i]].lfirstname, lname: currentStudentData.students[Object.keys(currentSemesterHours)[i]].lastname, email: currentStudentData.students[Object.keys(currentSemesterHours)[i]].pisd, id: currentStudentData.students[Object.keys(currentSemesterHours)[i]].idnumber, grade: currentStudentData.students[Object.keys(currentSemesterHours)[i]].gradelevel, shours: currentSemesterHours[Object.keys(currentSemesterHours)[i]].springhours, fhours: currentSemesterHours[Object.keys(currentSemesterHours)[i]].fallhours, met: "Yes" });
                        }
                    }
                    }
                } else if (type2 === "both") {
                    for (let i = 0; i < Object.keys(currentSemesterHours).length; i++) {
                        if (currentStudentData.students[Object.keys(currentSemesterHours)[i]]) {
                        if (currentSemesterHours[Object.keys(currentSemesterHours)[i]].springhours >= 15 && currentSemesterHours[Object.keys(currentSemesterHours)[i]].fallhours >= 15) {
                            renderRows.push({ fname: currentStudentData.students[Object.keys(currentSemesterHours)[i]].lfirstname, lname: currentStudentData.students[Object.keys(currentSemesterHours)[i]].lastname, email: currentStudentData.students[Object.keys(currentSemesterHours)[i]].pisd, id: currentStudentData.students[Object.keys(currentSemesterHours)[i]].idnumber, grade: currentStudentData.students[Object.keys(currentSemesterHours)[i]].gradelevel, shours: currentSemesterHours[Object.keys(currentSemesterHours)[i]].springhours, fhours: currentSemesterHours[Object.keys(currentSemesterHours)[i]].fallhours, met: "Yes" });
                        }
                        else if (currentSemesterHours[Object.keys(currentSemesterHours)[i]].springhours < 15 || currentSemesterHours[Object.keys(currentSemesterHours)[i]].fallhours < 15) {
                            renderRows.push({ fname: currentStudentData.students[Object.keys(currentSemesterHours)[i]].lfirstname, lname: currentStudentData.students[Object.keys(currentSemesterHours)[i]].lastname, email: currentStudentData.students[Object.keys(currentSemesterHours)[i]].pisd, id: currentStudentData.students[Object.keys(currentSemesterHours)[i]].idnumber, grade: currentStudentData.students[Object.keys(currentSemesterHours)[i]].gradelevel, shours: currentSemesterHours[Object.keys(currentSemesterHours)[i]].springhours, fhours: currentSemesterHours[Object.keys(currentSemesterHours)[i]].fallhours, met: "No" });
                        }
                    }
                    }
                }
            }
        
            if (type1 === "fallonly") {
                return (
                    <>
                        <table className="min-w-full">
                            <thead>
                                <tr>
                                    {headers2.map((header, ind) => (
                                        <th key={ind} className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">{header}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody className="bg-white">
                                {renderRows.map((student, ind) => (
                                    <tr key={ind}>
                                        <td className="px-6 py-4 border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">{student.fname}</td>
                                        <td className="px-6 py-4 border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">{student.lname}</td>
                                        <td className="px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-500">{student.email}</td>
                                        <td className="px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-500">{student.id}</td>
                                        <td className="px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-500">{student.grade}</td>
                                        <td className="px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-500">{student.fhours}</td>
                                        <td className="px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-500">{student.met}</td>
                                    </tr>
                                ))}

                            </tbody>
                        </table>
                    </>
                )
            }

            else if (type1 === "springonly") {
                return (
                    <>
                        <table className="min-w-full">
                            <thead>
                                <tr>
                                    {headers2.map((header, ind) => (
                                        <th key={ind} className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">{header}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody className="bg-white">
                                {renderRows.map((student, ind) => (
                                    <tr key={ind}>
                                        <td className="px-6 py-4 border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">{student.fname}</td>
                                        <td className="px-6 py-4 border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">{student.lname}</td>
                                        <td className="px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-500">{student.email}</td>
                                        <td className="px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-500">{student.id}</td>
                                        <td className="px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-500">{student.grade}</td>
                                        <td className="px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-500">{student.shours}</td>
                                        <td className="px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-500">{student.met}</td>
                                    </tr>
                                ))}

                            </tbody>
                        </table>
                    </>
                )
            }

            else if (type1 === "both") {
                return (
                    <>
                        <table className="min-w-full">
                            <thead>
                                <tr>
                                    {headers2.map((header, ind) => (
                                        <th key={ind} className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">{header}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody className="bg-white">
                                {renderRows.map((student, ind) => (
                                    <tr key={ind}>
                                        <td className="px-6 py-4 border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">{student.fname}</td>
                                        <td className="px-6 py-4 border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">{student.lname}</td>
                                        <td className="px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-500">{student.email}</td>
                                        <td className="px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-500">{student.id}</td>
                                        <td className="px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-500">{student.grade}</td>
                                        <td className="px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-500">{student.fhours}</td>
                                        <td className="px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-500">{student.shours}</td>
                                        <td className="px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-500">{student.met}</td>
                                    </tr>
                                ))}

                            </tbody>
                        </table>
                    </>
                )
            }

        }
    }

    const renderTableHead = () => {
        if (page === "Query Shirt Sizes") {
            return (
                <tr>
                    <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">First Name</th>
                    <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Last Name</th>
                    <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">PISD</th>
                    <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">ID</th>
                    <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Shirt Size</th>
                </tr>
            )
        }
    }

    const renderTable = () => {
        if (page === "Query Shirt Sizes") {
            return currentShirts.map((student, ind) => (
                <tr key={ind}>
                    <td className="px-6 py-4 border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">{student.fname}</td>
                    <td className="px-6 py-4 border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">{student.lname}</td>
                    <td className="px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-500">{student.email}</td>
                    <td className="px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-500">{student.id}</td>
                    <td className="px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-500">{student.size}</td>
                </tr>
            ));
        }
    }

    const convertToCSV = (data) => {
        const headers = ['First Name', 'Last Name', 'PISD', 'ID', 'Grade'];
        const rows = data.map(student => [
            student.fname, 
            student.lname, 
            student.email, 
            student.id, 
            student.grade
        ]);
    
        rows.unshift(headers);
    
        return rows.map(row => row.join(',')).join('\n');
    };

    const convertToCSV2 = (data) => {
        const headers = ['First Name', 'Last Name', 'PISD', 'ID', 'Shirt Size'];
        const rows = data.map(student => [
            student.fname, 
            student.lname, 
            student.email, 
            student.id, 
            student.size
        ]);

        const countsTable = [
            ['Size', 'Count'],
            ['S', currentShirtSizes.S],
            ['M', currentShirtSizes.M],
            ['L', currentShirtSizes.L],
            ['XL', currentShirtSizes.XL],
            ['XLL', currentShirtSizes.XXL]
        ];
    
        rows.unshift(headers);
    
        const mainTableCSV = rows.map(row => row.join(',')).join('\n');
        const countsTableCSV = countsTable.map(row => row.join(',')).join('\n');

        return `${mainTableCSV}\n\nCounts Table\n${countsTableCSV}`;
    };

    const convertToCSV3 = (data) => {
        const headers = headers2;
        var rows = [];

        console.log(data)

        if (currentSemesterHoursCheckbox.sem === "fall") {
            rows = data.map(student => [
                student.fname, 
                student.lname, 
                student.email, 
                student.id, 
                student.grade,
                student.fhours,
                student.met
            ]);
        }
        else if (currentSemesterHoursCheckbox.sem === "spring") {
            rows = data.map(student => [
                student.fname, 
                student.lname, 
                student.email, 
                student.id, 
                student.grade,
                student.shours,
                student.met
            ]);
        }
        else if (currentSemesterHoursCheckbox.sem === "both") {
            rows = data.map(student => [
                student.fname, 
                student.lname, 
                student.email, 
                student.id, 
                student.grade,
                student.fhours,
                student.shours,
                student.met
            ]);
        }

        rows.unshift(headers);
    
        return rows.map(row => row.join(',')).join('\n');
    };

    const downloadCSV3 = (data, filename) => {
        const csv = convertToCSV3(data);
        const blob = new Blob([csv], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
    };    

    const downloadCSV2 = (data, filename) => {
        const csv = convertToCSV2(data);
        const blob = new Blob([csv], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
    };    

    const downloadCSV = (data, filename) => {
        const csv = convertToCSV(data);
        const blob = new Blob([csv], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
    };    

    const renderInductionTables = () => {
        const handleDownload = (title, data) => {
            const filename = `${title.replace(/\s+/g, '_').toLowerCase()}.csv`;
            downloadCSV(data, filename);
        };

        if (page === "Query Induction Lists") {
            return (
                <>
                    <h1 className="text-lg font-semibold mt-5 mb-5 text-center cursor-pointer" onClick={() => handleDownload('Inducted Seniors', currentInductedLists[0])}>Inducted Seniors</h1>
                    <table className="min-w-full">
                        <thead>
                            <tr>
                                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">First Name</th>
                                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Last Name</th>
                                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">PISD</th>
                                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">ID</th>
                                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Grade</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white">
                            {currentInductedLists[0] && Object.values(currentInductedLists[0]).map((student, ind) => (
                                <tr key={ind}>
                                    <td className="px-6 py-4 border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">{student.fname}</td>
                                    <td className="px-6 py-4 border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">{student.lname}</td>
                                    <td className="px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-500">{student.email}</td>
                                    <td className="px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-500">{student.id}</td>
                                    <td className="px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-500">{student.grade}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <h1 className="text-lg font-semibold mt-5 mb-5 text-center cursor-pointer" onClick={() => handleDownload('Senior Candidates', currentInductedLists[1])}>Senior Candidates</h1>
                    <table className="min-w-full">
                        <thead>
                            <tr>
                                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">First Name</th>
                                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Last Name</th>
                                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">PISD</th>
                                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">ID</th>
                                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Grade</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white">
                            {currentInductedLists[1] && Object.values(currentInductedLists[1]).map((student, ind) => (
                                <tr key={ind}>
                                    <td className="px-6 py-4 border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">{student.fname}</td>
                                    <td className="px-6 py-4 border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">{student.lname}</td>
                                    <td className="px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-500">{student.email}</td>
                                    <td className="px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-500">{student.id}</td>
                                    <td className="px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-500">{student.grade}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <h1 className="text-lg font-semibold mt-5 mb-5 text-center cursor-pointer" onClick={() => handleDownload('Inducted Juniors', currentInductedLists[2])}>Inducted Juniors</h1>
                    <table className="min-w-full">
                        <thead>
                            <tr>
                                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">First Name</th>
                                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Last Name</th>
                                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">PISD</th>
                                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">ID</th>
                                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Grade</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white">
                            {currentInductedLists[2] && Object.values(currentInductedLists[2]).map((student, ind) => (
                                <tr key={ind}>
                                    <td className="px-6 py-4 border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">{student.fname}</td>
                                    <td className="px-6 py-4 border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">{student.lname}</td>
                                    <td className="px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-500">{student.email}</td>
                                    <td className="px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-500">{student.id}</td>
                                    <td className="px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-500">{student.grade}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <h1 className="text-lg font-semibold mt-5 mb-5 text-center cursor-pointer" onClick={() => handleDownload('Junior Candidates', currentInductedLists[3])}>Junior Candidates</h1>
                    <table className="min-w-full">
                        <thead>
                            <tr>
                                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">First Name</th>
                                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Last Name</th>
                                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">PISD</th>
                                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">ID</th>
                                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Grade</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white">
                            {currentInductedLists[3] && Object.values(currentInductedLists[3]).map((student, ind) => (
                                <tr key={ind}>
                                    <td className="px-6 py-4 border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">{student.fname}</td>
                                    <td className="px-6 py-4 border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">{student.lname}</td>
                                    <td className="px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-500">{student.email}</td>
                                    <td className="px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-500">{student.id}</td>
                                    <td className="px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-500">{student.grade}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <h1 className="text-lg font-semibold mt-5 mb-5 text-center cursor-pointer" onClick={() => handleDownload('Sophomore Candidates', currentInductedLists[4])}>Sophomore Candidates</h1>
                    <table className="min-w-full">
                        <thead>
                            <tr>
                                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">First Name</th>
                                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Last Name</th>
                                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">PISD</th>
                                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">ID</th>
                                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Grade</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white">
                            {currentInductedLists[4] && Object.values(currentInductedLists[4]).map((student, ind) => (
                                <tr key={ind}>
                                    <td className="px-6 py-4 border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">{student.fname}</td>
                                    <td className="px-6 py-4 border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">{student.lname}</td>
                                    <td className="px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-500">{student.email}</td>
                                    <td className="px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-500">{student.id}</td>
                                    <td className="px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-500">{student.grade}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </>
            )
        }
    }

    return (
        <main className="flex-1 relative z-0 overflow-y-auto pt-2 pb-6 focus:outline-none md:py-6" tabIndex="0">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                <NavLink to="/students/" exact className="hidden text-gray-900 hover:text-gray-700 md:block">
                    <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd"></path>
                    </svg>
                </NavLink>
                <h1 className="text-2xl font-semibold text-gray-900">Query the NHS Database</h1>
                <div className="bg-white shadow sm:rounded-lg mt-4">
                    <div className="p-4 bg-white shadow sm:rounded-lg my-4">
                        <div className="flex flex-col p-2 w-full">
                            <label>Query for...</label><br />
                            <div className='w-full flex flex-row gap-3'>
                                <button onClick={getShirts} className="w-1/8 rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-gray-700 transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                                    Shirt Sizes
                                </button>
                                <button onClick={getSemesterHours} className="w-1/8 rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-gray-700 transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                                    Semester Hours
                                </button>
                                <button onClick={getInductedLists} className="w-1/8 rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-gray-700 transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                                    Induction Lists
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                <div className="py-4">
                    <div className="flex flex-col">
                        <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                            <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                                <h2 className="pt-4 text-lg font-semibold text-gray-900 ml-2 mb-2">{page}</h2>
                                <div className="ml-5 mb-3">{renderExtras()}</div>
                                <div>{renderCustomHoursTables()}</div>
                                <div>{renderInductionTables()}</div>
                                <table className="min-w-full">
                                    <thead>{renderTableHead()}</thead>
                                    <tbody className="bg-white">{renderTable()}</tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default DatabaseQueryPage;
