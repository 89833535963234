import { React, useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import firebase from "firebase/compat/app";
import 'firebase/compat/auth'; import "react-dropzone-uploader/dist/styles.css";
// Page to grant students admin
const GrantAdminPage = () => {
    const [currentAdmin, setCurrentAdmin] = useState([]);
    const [uidEntered, setUID] = useState('');

    const getAdmins = () => {
        firebase
            .auth()
            .currentUser.getIdToken(true)
            .then((idToken) => {
                fetch("https://api.peshnhs.org/getAdminList",
                    {
                        method: "GET",
                        headers: {
                            'idtoken': idToken,
                        }
                    }
                )
                    .then((result) => result.json()).then((result) => {
                        setCurrentAdmin(result['admins']);
                    })
            }).catch((error) => { console.log(error) });
    }

    const changeAdmin = (remove, uid) => {
        console.log((remove) ? 'removing ' + uid : 'adding ' + uid);

        firebase
            .auth()
            .currentUser.getIdToken(true)
            .then((idToken) => {
                fetch("https://api.peshnhs.org/modifyAdmin?" +
                    "uid=" + uid +  
                    "&remove=" + remove,
                    {
                        method: "GET",
                        headers: {
                            'idtoken': idToken,
                        }
                    }
                )
                    .then((result) => result.json()).then((result) => {
                        console.log(result);
                        getAdmins();
                    })
            }).catch((error) => { console.log(error) });
    }

    useEffect(() => {
        getAdmins();
    }, [])


    const renderTable = () => {
        if (!currentAdmin || currentAdmin.length === 0) {
            return (
                <td class="px-6 py-4 border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                    Loading students...
                </td>
            );
        }

        return (
            <>
                {
                    Object.values(currentAdmin).map((student, ind) => {
                        return (
                            <tr key={ind}>
                                <td class="px-6 py-4 border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                                    {student['displayName'].split(" ")[0]}
                                </td>

                                <td class="px-6 py-4 border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                                    {student['displayName'].split(" ")[1]}
                                </td>

                                <td class="px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-500">
                                    {student['uid']}
                                </td>

                                <td class="px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-500">
                                    {student['email']}
                                </td>
                                <td>
                                    <a
                                        class="text-red-600"
                                        onClick={() => changeAdmin(true, student['uid'])}
                                    >
                                        <svg
                                            class="w-8 h-8"
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fill-rule="evenodd"
                                                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                                                clip-rule="evenodd"
                                            ></path>
                                        </svg>
                                    </a>
                                </td>
                            </tr>
                        );
                    })
                }
            </>
        );
    }

    return (
        <main
            className="flex-1 relative z-0 overflow-y-auto pt-2 pb-6 focus:outline-none md:py-6"
            tabindex="0"
            x-data
            x-init="$el.focus()"
        >

            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                <NavLink
                    to="/students/"
                    exact
                    className="hidden text-gray-900 hover:text-gray-700 md:block"
                >
                    <svg
                        class="w-6 h-6"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                            clip-rule="evenodd"
                        ></path>
                    </svg>
                </NavLink>
                <h1 className="text-2xl font-semibold text-gray-900">
                    Grant Admin for NHS Officers
                </h1>
                <div class="bg-white shadow sm:rounded-lg mt-4">
                    <div class="p-4 bg-white shadow sm:rounded-lg my-4">
                        <div class="flex flex-col p-2 w-full">
                            <label>UID</label>
                            <div class='w-full flex flex-row justify-between'>
                                <input
                                    class="h-1/4 mt-1 form-input w-full py-2 px-3 mr-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                    onChange={(e) => { setUID(e.target.value) }}
                                />
                                <button
                                    onClick={() => {changeAdmin(false, uidEntered)}}
                                    type="submit"
                                    class="w-1/8 rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-gray-700 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                <div className="py-4">
                    <div class="flex flex-col">
                        <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                            <div class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">

                                <h2 className="pt-4 text-lg font-semibold text-gray-900 ml-2 mb-2">
                                    Current Admins in System
                                </h2>
                                <table class="min-w-full">
                                    <thead>
                                        <tr>
                                            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                                First Name
                                            </th>
                                            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                                Last Name
                                            </th>
                                            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                                UID
                                            </th>
                                            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                                PISD
                                            </th>
                                            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"></th>
                                        </tr>
                                    </thead>
                                    <tbody class="bg-white">{renderTable()}</tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default GrantAdminPage;